import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/OpenHAB_Home_Automation/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB 2 Installation on Windows",
  "path": "/Advanced_User/OpenHAB_Home_Automation/OpenHAB_Installation_on_Windows/",
  "dateChanged": "2018-09-28",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "../AU_SearchThumb_OpenHAB.png",
  "social": "/images/Search/AU_SearchThumb_OpenHAB.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='OpenHAB 2 Installation on Windows' dateChanged='2018-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_OpenHAB.png' twitter='/images/Search/AU_SearchThumb_OpenHAB.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/OpenHAB_Heimautomatisierung/OpenHAB_Installation_unter_Windows/' locationFR='/fr/Advanced_User/OpenHAB_Home_Automation/OpenHAB_Installation_on_Windows/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "openhab-2-installation-on-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-2-installation-on-windows",
        "aria-label": "openhab 2 installation on windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB 2 Installation on Windows`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openHAB-2-on-Windows"
        }}>{`openHAB 2 on Windows`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Prerequisites"
            }}>{`Prerequisites`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Installation"
            }}>{`Installation`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "openhab-2-on-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab-2-on-windows",
        "aria-label": "openhab 2 on windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`openHAB 2 on Windows`}</h2>
    <p>{`The following instructions will guide you through the process of setting up `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/"
      }}>{`openHAB 2`}</a>{`.`}</p>
    <h3 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h3>
    <p>{`First we need to `}<a parentName="p" {...{
        "href": "https://java.com/en/"
      }}>{`Download`}</a>{` and install the JAVA Development Kit (JDK or openJDK). Then add the JAVA_HOME System Environment Variable in Windows in the Control Panel:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`System and Security ➡️ System ➡️ Advanced System Settings ➡️ Environment Variables ➡️ System Variables`}</code></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/80682eec892303969ca4b223623146ba/e9beb/openhab2_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACLUlEQVQoz42SyW/TQBSH86dyYSk9IKFe4IJY/gbucAEJVQIR1AVaodJADKiVCm1Km7hLktoez77a47EH7KRRi4rEp9G7fTO/N++1Yqqdta6qrHOVK/1/UJ3TqktZAiopN8Hx4Z2N9v0v7xc6S3c/L9/7vr7QXX3wo/OkFzze6z7a6z7c6Yw59d6XZVnLWmcIMYSYt+XXeHjtw+KttddzG+25YHkuWJkPVm9svru+2b7ZeXe7uzIfrBykcS1Xlfe+laQpY5pSWXkvtM4gkRFQEcgBqqiwKckSaGKYJTAHyGojhJglb/X3D07648GvU80V4yzF6KA/SBEinEGMEaGEMco55ZxLSaTEjE3k+uXxy1dgaS1uryZvlqP1j7krlJRaKdmglG6qiqIIJIArxZqXJ7S8c74oJocwljdMLr4ISGu4UqOziBJqjGnkCzCtMcJheBTHMcYEYyykaKZRTq6TxsQAWGunsS/KRArnXN1SWeOcK8vp5EfDGqHVYX+QgvQKmSlFKY3jJEkSznlRFFmWGWOyLLd57opCGg0JdkUxnfNfMmMMJCBNIYSIMQYhQghTyqRSWZYJownndbTph10V+9IyFs5KfRKGp4OQEkLFP2U5a3I2THk0/Pb0+daLt1vPFkefAmb0bLkvy4Jnxtg8L6wtrDVac86VEDAGGEAYxePxGaLUV941P3pJxpwfn42Ox6PB6Uk4HO4P+tu7P3uTGvZ39nvbvV12vp5/Yv8GVPNF/vPCXoEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/80682eec892303969ca4b223623146ba/e4706/openhab2_01.avif 230w", "/en/static/80682eec892303969ca4b223623146ba/d1af7/openhab2_01.avif 460w", "/en/static/80682eec892303969ca4b223623146ba/b6582/openhab2_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/80682eec892303969ca4b223623146ba/a0b58/openhab2_01.webp 230w", "/en/static/80682eec892303969ca4b223623146ba/bc10c/openhab2_01.webp 460w", "/en/static/80682eec892303969ca4b223623146ba/87ca7/openhab2_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/80682eec892303969ca4b223623146ba/81c8e/openhab2_01.png 230w", "/en/static/80682eec892303969ca4b223623146ba/08a84/openhab2_01.png 460w", "/en/static/80682eec892303969ca4b223623146ba/e9beb/openhab2_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/80682eec892303969ca4b223623146ba/e9beb/openhab2_01.png",
              "alt": "Set JAVA_HOME",
              "title": "Set JAVA_HOME",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the Windows Control Panel, go to the `}<strong parentName="p">{`Advanced System Settings`}</strong>{` and open the `}<strong parentName="p">{`Environment Variables`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/737a2520878a7a97978ded6c862dcc77/e9beb/openhab2_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.43478260869566%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACeklEQVQoz3VS247bNhT0t+Vl2+/IjxUIkDQPQQoUCdKgL4tsiwBtsmm8a3tjWxeLd/FQvEmWJVEsJDfbzUMGA4IAOZw553AB/hhjvJXs0dtnP/7+/OLts4vLFxd//HJx9fJ7/OHq5aN3Lx5f/boIIZxOvQAALkpEJCJlgWtV9a4+GdvOPH3LVpve+ZLzhXUepC0IJ5ynaQ6gtDb90HMh6qYZYxxC6IehH4YhhJlDH4ah7w8ELxAh1tQF5QjjzfqOMQ6gur4jGCf7NEuzLEsBwFlXfYVSqjudEKOLu+02S9l6myOMKWExxjGMMUaKyd/v/1p++vzp+np1u14tV2UpvfN2xtD3BSULY23bdFwqqaDrujipJ3F7bK2x9Yy2PR2bYwghxvP5dGESn3fGe1kp77217vy2c87P0NoQQgBACIExkRIQxoyyqeaz2NaeCS6EYIwBgFKqqiqYwadGpoQQjHGe54yxLMtQgQry1dk29QEVyW7PGHdzYaUoFSgzYUpxH+e/1dgco3vnmjCW7BKEsJJqKkTrruvjd9B3XVYUD8V0e7fd7xKQEOMoy5JiKrhQAFprBWoKojWUUFX62DQPxM3kLBi/b6aUcrO6S/ZJVVUxxjD9kTCOIYzhnOtLknzjvF5tDnlhrR3HkRKazCmgqqyvjfPaGGus0Wau2qWHw/+j4rJkjGOMGedK61JKSikVZf76zfKnJ8nTn7cfPqy+bNIkubm52e32+cNROW1O3rfeH51rjD1a1zrfOn+kzOUHd0AMcYQFlEpSqtg01MUwDH3XgXfXpLhhaEnRkqElw/M6s6S3wD8K9tt6+2q5udwll7f//LndfMyTfwEwAmmKt0AojQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/737a2520878a7a97978ded6c862dcc77/e4706/openhab2_02.avif 230w", "/en/static/737a2520878a7a97978ded6c862dcc77/d1af7/openhab2_02.avif 460w", "/en/static/737a2520878a7a97978ded6c862dcc77/b6582/openhab2_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/737a2520878a7a97978ded6c862dcc77/a0b58/openhab2_02.webp 230w", "/en/static/737a2520878a7a97978ded6c862dcc77/bc10c/openhab2_02.webp 460w", "/en/static/737a2520878a7a97978ded6c862dcc77/87ca7/openhab2_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/737a2520878a7a97978ded6c862dcc77/81c8e/openhab2_02.png 230w", "/en/static/737a2520878a7a97978ded6c862dcc77/08a84/openhab2_02.png 460w", "/en/static/737a2520878a7a97978ded6c862dcc77/e9beb/openhab2_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/737a2520878a7a97978ded6c862dcc77/e9beb/openhab2_02.png",
              "alt": "Set JAVA_HOME",
              "title": "Set JAVA_HOME",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select to set a new System variable `}<strong parentName="p">{`JAVA_HOME`}</strong>{` and point to your JAVA installation path (that `}<em parentName="p">{`might differ`}</em>{` from the one shown below): `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/276445104d53c154c3460394929c1c47/e9beb/openhab2_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACu0lEQVQoz3WS2YrcRhSG+wGdm4DtjP0WeZ7cmJkxgzEBu8cEchVjTxt7tl7k7lFLrbX2UlWpVJJaUmsJmnGCIfHPT1EHznd+ilMTmVd91xVlmRuT53mWZfv9vmma+seqqqppGinlpO+7uj4IoRCCYRBFQRT6Iec8TVP5AwkhlEoxxhNjCiEyQgRjzPcCIWSqdNu2jLKyKIdh6Lq+7bq27b5X27YIoQmASKkCIYYg2nzdEExEIg6HQxzHO3fn73x/53HOM52p77KrqiKETO62ju9R140hgAji+6huGAYYgy+zz/Obxc3ltbVYWcsVZ8xkmU611vpwOGBMJlrrsqwTIZVUTd0Mw9D3/TAM1b7KtC6KIjd5VVX7snwY+m/D+OaHOivyRMo0y5TW8t4qy1JjUmNEqmKIaJJgSiMAKOchAADjGMLJw5ihbppU10o3SrfadNq0/7gSSkQgp9wQpgDac6FiqBHRlI3JlmRP//r95z9ePnrz4qc/z57Ozp98nD758Hb0x7e/XEyfzc6PLqZHs/Nnn94dXUyfz949np3/+n46wjvBfpt/erH6cry+OXXmp/bVmTt/HVqvQ+uVb526qxN3+XAeu8sTd3niLE486/h6NsImNyKCzI96k4NYXlzHc1vYUW55WYSKoazbou6Kui/rYd8M5Xgf6j4KohGWJvOCwPU8SvGll5xd0ZefwatLdHZF3zsqMSVJC6pLn5stUh43EVdQZCvX+wZvtvbtanE9X3j+Lgoca3nrbjcER5wRgDGmBFOycKLZwr6xg7W9Xd9tbr9aI9y2bSIFJBjRsTVGGBACMIkQihHyw3C+XNqO47jO+m7jB761Xq9t2/V23/a8L0utdfYf3X+plDEmpWSMIoiSJEEQYowZpZO2bZvmUJQl5zz5PwkhpJRJkoRhuPM8AKFt277vAwD+BkUoXGKeRrWSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/276445104d53c154c3460394929c1c47/e4706/openhab2_03.avif 230w", "/en/static/276445104d53c154c3460394929c1c47/d1af7/openhab2_03.avif 460w", "/en/static/276445104d53c154c3460394929c1c47/b6582/openhab2_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/276445104d53c154c3460394929c1c47/a0b58/openhab2_03.webp 230w", "/en/static/276445104d53c154c3460394929c1c47/bc10c/openhab2_03.webp 460w", "/en/static/276445104d53c154c3460394929c1c47/87ca7/openhab2_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/276445104d53c154c3460394929c1c47/81c8e/openhab2_03.png 230w", "/en/static/276445104d53c154c3460394929c1c47/08a84/openhab2_03.png 460w", "/en/static/276445104d53c154c3460394929c1c47/e9beb/openhab2_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/276445104d53c154c3460394929c1c47/e9beb/openhab2_03.png",
              "alt": "Set JAVA_HOME",
              "title": "Set JAVA_HOME",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.openhab.org/download/"
      }}>{`Download`}</a>{` the latest Windows Stable or Snapshot ZIP archive file for manual installation and unzip the file in your chosen directory (e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`C:\\openHAB2`}</code>{`).`}</p>
    <p>{`To start the server, launch the runtime by executing the script `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`C:\\openhab230\\start.bat`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cc7f0ed493373bf3a5e9e17ece38c46d/e9beb/openhab2_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB7klEQVQoz4WQa2vTUBzG8yXmBWFd06aXMXrJZVtZtdDWpUm64cBhERW/jy90MJ3rmk1dWxBX/Bh+CrGCKKNr2uQkTc4tkaadvpDNhx+Hczg8/+c8h+l+6ZvWaGJbAAAEEUIIQuhfKpjLDwKfEEoIwaF8SscOZBxIXc9F0IEQ2jawLOC67sxJCLWdycT1IIQzj+tN9wghSikmhBlbg+HFcBaBJz+AZV6mBZRSAIBlmbYNHMcJ/hHz9dz8+escY0yobw7737/1IUKYEEwIQsgwjMHgwjCM0djEmCCMPQ/ObqfJwCM2AEFYz3bAaDj8M9j3fei5njtB4VfMn0PI3+RXze7Lg3d6+0xv91632i/2mntv3+udnt7unXQ/Hxx395unb1qd/aP20YdPrdOzw5OPeqd3HMJE87WYpLGCwgpqVFAiuc07K+WbqXsL3Mbt5dKtdGkhUbyRvDsnUZwTHhm+3Cgoz9bkJ0KlkS5sJ1e11PoWJykxoRbj5SlC7SqYuKBygsYJWkKqpwvbqfWtaH4zkq0u5e7/F4YTNU6sc2I9LqorxZ1saZfl5cVMJZKtRsL1OnOm9FCsPs6UdvlKY1V+WlCf8+VHnKRykppcq7O8vJS70s8kNh4sF3dYftohLipsWDIuKnFR4SQ1mpevSf4NhbySPRZRD4YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc7f0ed493373bf3a5e9e17ece38c46d/e4706/openhab2_04.avif 230w", "/en/static/cc7f0ed493373bf3a5e9e17ece38c46d/d1af7/openhab2_04.avif 460w", "/en/static/cc7f0ed493373bf3a5e9e17ece38c46d/b6582/openhab2_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cc7f0ed493373bf3a5e9e17ece38c46d/a0b58/openhab2_04.webp 230w", "/en/static/cc7f0ed493373bf3a5e9e17ece38c46d/bc10c/openhab2_04.webp 460w", "/en/static/cc7f0ed493373bf3a5e9e17ece38c46d/87ca7/openhab2_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc7f0ed493373bf3a5e9e17ece38c46d/81c8e/openhab2_04.png 230w", "/en/static/cc7f0ed493373bf3a5e9e17ece38c46d/08a84/openhab2_04.png 460w", "/en/static/cc7f0ed493373bf3a5e9e17ece38c46d/e9beb/openhab2_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cc7f0ed493373bf3a5e9e17ece38c46d/e9beb/openhab2_04.png",
              "alt": "openhab2",
              "title": "openhab2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Point your browser to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:8080`}</code>{`. You should be looking at the openHAB `}<a parentName="p" {...{
        "href": "https://docs.openhab.org/configuration/packages.html"
      }}>{`package selection`}</a>{` page:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/14c48a0aa5075b2ad3b667deb8ec65ef/e9beb/openhab2_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACOElEQVQoz42QW0hTcQDGz3MvIQTCICiUKCh7LYqIeuhFaGUYumZqhREEOg2brrJtbbNtbrO15uacu2Sdna21S/Myh0S6c9wmie0i4YiBWBJRhp3LzuUfWyPqqT5+fHwvv5cPMr9PI4W8ay3Thk7Xx0N89NXZ5Mz/cD41CwG1EGhagLWHsXQz432svZfVXGK1Lf+E0Qqh4qioOHaL8ihp72DRp6E9KtoqokY6K1i6KKuo1GWKf2+IBIAEYJsDP8oDB2CLAzSohCvzO+wfmwEAogicIvDXk0H/M1fIh0T8yPxczDisHzE9Nui04WAgOjPndDyfcCMI/DKVSCIw7PUgTocTQ+MQRVEkSRnHHFK1TmUwDltsj56Yq6p5/MYm3t5aQWs7/CIgU+tVOqPebAtEpnsHHsgGtV199ya8/pJMkWQh5i9E3OuzyDdscsHnun7iYP+F0x3H9pvEN4kcthUPfkUj20vRj8vxFdi0jFhTTkN+PlqWKeqzS7Yhv/hJ27apaf0yNb6hvloYulGQCzcR/br9fl7anFe251VX8Dc+4JYUYQVt62YWvBV51O6Q3O6U3ulRSsWBcFisGBpQqSVy5VPEd6q+YUf1nl01h3bu3reSWwUAEDRbuplhKnI2m0Hj6CK2mEqmsqtroRiGolgCQ7OZ9Jlzgqq6kzV1R3i1h9+lsyUZx0s/kWRFZh39nKKB011mNQJ6ygIeNrKGdk7Op2FF5m5zouP422tHl5oOfP+QowEgCeKX9RPEzc0j4DfaTQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/14c48a0aa5075b2ad3b667deb8ec65ef/e4706/openhab2_05.avif 230w", "/en/static/14c48a0aa5075b2ad3b667deb8ec65ef/d1af7/openhab2_05.avif 460w", "/en/static/14c48a0aa5075b2ad3b667deb8ec65ef/b6582/openhab2_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/14c48a0aa5075b2ad3b667deb8ec65ef/a0b58/openhab2_05.webp 230w", "/en/static/14c48a0aa5075b2ad3b667deb8ec65ef/bc10c/openhab2_05.webp 460w", "/en/static/14c48a0aa5075b2ad3b667deb8ec65ef/87ca7/openhab2_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/14c48a0aa5075b2ad3b667deb8ec65ef/81c8e/openhab2_05.png 230w", "/en/static/14c48a0aa5075b2ad3b667deb8ec65ef/08a84/openhab2_05.png 460w", "/en/static/14c48a0aa5075b2ad3b667deb8ec65ef/e9beb/openhab2_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/14c48a0aa5075b2ad3b667deb8ec65ef/e9beb/openhab2_05.png",
              "alt": "openhab2",
              "title": "openhab2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      